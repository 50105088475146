import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./screens/Home";
import UserLayout from "./screens/UserLayout";
import Auth from "./screens/Auth";

function App() {
  // const dispatch = useDispatch();
  // const { pathname } = useLocation();
  // useEffect(() => {
  //   window.scrollTo(0, 0);

  //   axios
  //     .get(api_url + "/seo/", {
  //       params: {
  //         slug: pathname,
  //       },
  //     })
  //     .then((res) => {
  //       dispatch(setUserPage(res.data));
  //     })
  //     .catch((err) => {});
  // }, [pathname]);

  return (
    <div className="App ">
      <Routes>
        <Route path="" element={<UserLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/auth" element={<Auth />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
