import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";

const Auth = () => {
  const [showRegisterInterface, setShowRegisterInterface] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  return (
    <div className="mx-auto w-fit flex flex-col mt-28 px-8">
      <h1 className="text-center font-bold text-3xl text-secondary">
        Autentificare
      </h1>
      <div className="grid grid-cols-2 mt-8">
        <button
          onClick={() => setShowRegisterInterface(false)}
          className={
            "text-2xl sm:text-xl font-bold transition-colors ease-in-out duration-200 " +
            (showRegisterInterface ? "text-[#C5C5C5]" : "text-secondary")
          }
        >
          <span className=" px-8 sm:px-6">Autentificare</span>
          <div className="h-1 flex flex-col w-full  mt-1.5">
            <div
              className={
                "w-full mt-auto transition-all ease-in-out duration-300 " +
                (showRegisterInterface
                  ? "h-0.5 bg-[#C5C5C5]"
                  : "h-1 bg-secondary")
              }
            ></div>
          </div>
        </button>
        <button
          onClick={() => setShowRegisterInterface(true)}
          className={
            "text-2xl sm:text-xl font-bold transition-colors ease-in-out duration-200 " +
            (showRegisterInterface ? "text-secondary" : "text-[#C5C5C5]")
          }
        >
          <span className=" px-8 sm:px-6">Înregistrare</span>
          <div className="h-1 flex flex-col w-full  mt-1.5">
            <div
              className={
                " w-full mt-auto transition-all ease-in-out duration-300 " +
                (showRegisterInterface
                  ? "h-1 bg-secondary"
                  : "h-0.5 bg-[#C5C5C5]")
              }
            ></div>
          </div>
        </button>
      </div>
      <div className="flex flex-col gap-y-4 mt-8 font-semibold text-lg">
        <button className="bg-primary w-full text-white py-2 rounded-md flex gap-x-2 justify-center items-center">
          <FaFacebook className="text-2xl" />
          Conectați-vă prin Facebook
        </button>
        <button className="border-tertiary/75 py-2 rounded-md w-full border-solid border-2 text-tertiary flex gap-x-2 justify-center items-center">
          <FcGoogle className="text-2xl" />
          Conectați-vă prin Google
        </button>
      </div>
      <div className="my-6 text-lg font-bold text-center">sau</div>
      {(showRegisterInterface && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="flex flex-col gap-y-3"
        >
          <input
            name="email"
            className="user-input"
            placeholder="Număr de telefon sau email"
          />
          <input type="password" className="user-input" placeholder="Parola" />
          <input
            name="confirmPassword"
            type="password"
            className="user-input"
            placeholder="Confirmă parola"
          />
          <button className="bg-primary text-white font-bold text-lg py-2 rounded-md mt-4">
            Înregistrați-vă
          </button>
        </form>
      )) || (
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="flex flex-col gap-y-3"
        >
          <input
            className="user-input"
            placeholder="Număr de telefon sau email"
          />
          <input type="password" className="user-input" placeholder="Parola" />
          <div className="flex gap-x-2 items-end">
            <button
              type="button"
              onClick={() => setRememberMe(!rememberMe)}
              className={
                "border-solid border-2 border-primary transition-colors ease-in-out duration-200 h-6 w-6 relative rounded " +
                (rememberMe ? "bg-primary" : "bg-white")
              }
            >
              {rememberMe && (
                <FaCheck className="text-white absolute absolute-center transition-colors ease-in-out duration-200" />
              )}
            </button>
            <span className="text-secondary font-semibold">
              Salvează datele
            </span>
          </div>
          <button className="bg-primary text-white font-bold text-lg py-2 rounded-md mt-4">
            Conectați-vă
          </button>
          <Link className="text-tertiary underline underline-offset-[0.5rem] mt-3 decoration-tertiary	block mx-auto w-fit">
            Ați uitat parola?
          </Link>
        </form>
      )}
    </div>
  );
};

export default Auth;
