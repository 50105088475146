import React, { useState } from "react";
import homeCar from "../static/images/home-car.png";
import { HiMiniMagnifyingGlass } from "react-icons/hi2";

const Home = () => {
  const [selectedRegion, setSelectedRegion] = useState("Moldova");
  const regions = ["Moldova", "Europa", "SUA"];
  const [number, setNumber] = useState("");
  const [faqsList, setFaqsList] = useState(faqs);

  return (
    <div className="">
      <div className="px-80 xl:px-64 lg:px-32 md:px-8 sm:px-6 pt-24 bg-background">
        <p className="text-secondary text-center text-lg">
          Raport privind istoricul vehiculelor în funcție de VIN
        </p>
        <h1 className="text-center font-bold text-6xl md:text-5xl sm:text-4xl mt-4 text-secondary leading-[4.75rem] px-40 md:px-8 xl:px-28 lg:px-14 sm:px-0">
          Istoric auto complet la doar un click distanță
        </h1>
        <div className="mt-20 flex justify-center gap-x-12 text-secondary font-medium">
          {regions.map((region, index) => (
            <button
              key={"region-" + index}
              onClick={() => setSelectedRegion(region)}
            >
              {region}
              <div
                className={
                  "mt-1 transition-all ease-in-out duration-200 h-0.5 mx-auto bg-secondary " +
                  (selectedRegion == region ? "w-full" : "w-0")
                }
              ></div>
            </button>
          ))}
        </div>
        <div className="mx-40 relative  sm:flex flex-col gap-y-4 sm:mx-0 xl:mx-32 lg:mx-28 md:mx-8 mt-4">
          <input
            placeholder={
              selectedRegion == "Moldova"
                ? "Introduceți numărul de înmatriculare"
                : "Introduceți codul VIN al vehiculului"
            }
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            className="w-full py-6 px-8 outline-none rounded-full bg-white text-tertiary"
          />
          <div className="absolute sm:relative sm:h-16 sm:-translate-y-4 top-0 right-0 h-full py-2 px-2  bottom-0 ">
            <button className="bg-primary h-full w-full rounded-full px-16 sm:px-0 sm:justify-center text-white flex gap-x-2 items-center">
              <HiMiniMagnifyingGlass className="text-lg" /> Verifică
            </button>
          </div>
        </div>
        <img src={homeCar} className="w-full translate-y-20" />
      </div>
      <div className="px-80 mt-40 xl:px-64 lg:px-32 md:px-8 sm:px-6">
        <h2 className=" text-center font-bold text-4xl text-secondary">
          Întrebări frecvente
        </h2>
        <div className="flex flex-col gap-y-4 px-40 mt-12 md:px-8 xl:px-28 lg:px-14 sm:px-0">
          {faqsList.map((faq) => (
            <div className="flex flex-col text-secondary">
              <button
                onClick={() => {
                  setFaqsList(
                    faqsList.map((f) => {
                      if (f.question == faq.question) {
                        return { ...f, opened: !f.opened };
                      }
                      return f;
                    })
                  );
                }}
                className="flex justify-between py-2 font-semibold text-lg"
              >
                <div className="text-left">{faq.question}</div>
                <span className="text-2xl">{faq.opened ? "-" : "+"}</span>
              </button>
              <div
                className={
                  "flex flex-col gap-y-2 scrollbar-none overflow-y-auto transition-all ease-in-out duration-500 " +
                  (faq.opened ? "max-h-[1000px]" : "max-h-0")
                }
              >
                {faq.answer.split("\n").map((line) => (
                  <p>{line}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-home-background py-20 mt-20 px-80 xl:px-64 lg:px-32 md:px-8 sm:px-6">
        <h2 className=" text-center font-bold text-4xl text-secondary">
          Raport privind istoricul vehiculului
        </h2>
        <p className="text-tertiary mt-2 text-center text-lg">
          Evită problemele costisitoare verificând istoricul vehiculelor.
        </p>
        <div className="mt-8 flex justify-center gap-x-12 text-secondary font-medium">
          {regions.map((region, index) => (
            <button
              key={"region-" + index}
              onClick={() => setSelectedRegion(region)}
            >
              {region}
              <div
                className={
                  "mt-1 transition-all ease-in-out duration-200 h-0.5 mx-auto bg-secondary " +
                  (selectedRegion == region ? "w-full" : "w-0")
                }
              ></div>
            </button>
          ))}
        </div>
        <div className="mx-40 sm:mx-0 xl:mx-32 lg:mx-28 md:mx-8 relative  mt-4">
          <input
            placeholder={
              selectedRegion == "Moldova"
                ? "Introduceți numărul de înmatriculare"
                : "Introduceți codul VIN al vehiculului"
            }
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            className="w-full py-6 px-8 outline-none rounded-full bg-white text-tertiary"
          />
          <div className="absolute sm:relative sm:h-16 top-0 right-0 h-full py-2 px-2  bottom-0 ">
            <button className="bg-primary h-full w-full rounded-full px-16 sm:px-0 sm:justify-center text-white flex gap-x-2 items-center">
              <HiMiniMagnifyingGlass className="text-lg" /> Verifică
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

const faqs = [
  {
    question: "Ce este un raport de istoric al vehiculului?",
    opened: false,
    answer:
      "Descoperă locuința ideală în inima zonei de business a Bucureștiului!🏡 Bucură-te de priveliști uimitoare și vezi de ce First Estates este locul perfect pentru tine. Modernitate, stil și rafinament – toate într-un singur loc.",
  },

  {
    question: "Pentru cine oferim rapoarte despre istoricul vehiculului?",
    opened: false,

    answer:
      "Descoperă locuința ideală în inima zonei de business a Bucureștiului!🏡 Bucură-te de priveliști uimitoare și vezi de ce First Estates este locul perfect pentru tine. Modernitate, stil și rafinament – toate într-un singur loc.",
  },
  {
    question:
      "Rapoartele Istoricauto conțin informații despre fiecare vehicul?",
    opened: false,

    answer:
      "Descoperă locuința ideală în inima zonei de business a Bucureștiului!🏡 Bucură-te de priveliști uimitoare și vezi de ce First Estates este locul perfect pentru tine. Modernitate, stil și rafinament – toate într-un singur loc.",
  },
  {
    question: "Ce este un VIN?",
    opened: false,

    answer:
      "Descoperă locuința ideală în inima zonei de business a Bucureștiului!🏡 Bucură-te de priveliști uimitoare și vezi de ce First Estates este locul perfect pentru tine. Modernitate, stil și rafinament – toate într-un singur loc.",
  },
  {
    question: "Unde pot găsi numărul meu VIN?",
    opened: false,

    answer:
      "Descoperă locuința ideală în inima zonei de business a Bucureștiului!🏡 Bucură-te de priveliști uimitoare și vezi de ce First Estates este locul perfect pentru tine. Modernitate, stil și rafinament – toate într-un singur loc.",
  },
];
