import React, { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import logo from "../static/images/logo.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import { motion } from "framer-motion";
const UserLayout = () => {
  const [openSidebar, setOpenSidebar] = useState(false);

  const logoComponent = (
    <div className="w-1/4 sm:w-full">
      <Link to="/" className="w-fit block sm:scale-125 sm:mx-auto">
        <div className="text-3xl font-bold flex">
          <img src={logo} className="mr-2" />
          <span className="text-secondary">istoric</span>
          <span className="text-primary">auto</span>
        </div>
      </Link>
    </div>
  );

  return (
    <div className=" flex flex-col min-h-screen">
      <motion.aside
        initial={{ x: "-100%" }}
        animate={{
          x: openSidebar ? 0 : "-100%",
          transition: { duration: 0.2, stiffness: 0 },
        }}
        className="fixed top-0 left-0 h-screen hidden sm:block w-full bg-white shadow z-50"
      >
        <button
          onClick={() => {
            setOpenSidebar(!openSidebar);
          }}
          className="absolute right-6 top-6"
        >
          <GiHamburgerMenu className="text-3xl" />
        </button>
        <nav className="text-tertiary w-full h-full flex flex-col items-center justify-center gap-y-6">
          <div
            className="w-1/4 sm:w-full mb-4"
            onClick={() => setOpenSidebar(false)}
          >
            <Link to="/" className="w-fit block sm:scale-125 sm:mx-auto">
              <div className="text-3xl font-bold flex">
                <img src={logo} className="mr-2" />
                <span className="text-secondary">istoric</span>
                <span className="text-primary">auto</span>
              </div>
            </Link>
          </div>
          <span onClick={() => setOpenSidebar(false)}>
            <Link
              to="/auth"
              className="outline-none text-center block w-fit mx-auto font-medium border-primary border-2 border-solid rounded-full px-6 py-2 text-primary"
            >
              Înregistrare / Autentificare
            </Link>
          </span>
          <span onClick={() => setOpenSidebar(false)}>
            <Link to="">Calculator de asigurare RCA</Link>
          </span>
          <span onClick={() => setOpenSidebar(false)}>
            <Link to="">Calculator taxă rutieră</Link>
          </span>
          <span onClick={() => setOpenSidebar(false)}>
            <Link to="">Calculator devamare</Link>
          </span>
          <span onClick={() => setOpenSidebar(false)}>
            <Link to="">Programare inspecție auto</Link>
          </span>
        </nav>
      </motion.aside>
      <header className="py-5 md:grid grid-cols-2 sm:py-6 px-12 lg:px-6 flex items-center bg-white shadow relative">
        <button
          onClick={() => {
            setOpenSidebar(!openSidebar);
          }}
          className="absolute left-6 top-1/2 -translate-y-1/2 hidden sm:block"
        >
          <GiHamburgerMenu className="text-3xl" />
        </button>
        <div className="w-1/4 sm:w-full">
          <Link to="/" className="w-fit block sm:scale-125 sm:mx-auto">
            <div className="text-3xl font-bold flex">
              <img src={logo} className="mr-2" />
              <span className="text-secondary">istoric</span>
              <span className="text-primary">auto</span>
            </div>
          </Link>
        </div>
        <nav className="text-tertiary sm:hidden w-2/4 md:col-span-2 md:w-full md:mt-6 md:gap-x-6 lg:gap-x-4 md:order-3 text-center flex items-center justify-center gap-x-10">
          <Link to="">Calculator de asigurare RCA</Link>
          <Link to="">Calculator taxă rutieră</Link>
          <Link to="">Calculator devamare</Link>
          <Link to="">Programare inspecție auto</Link>
        </nav>
        <div className="w-1/4 md:w-full sm:hidden">
          <Link
            to="/auth"
            className="outline-none text-center block w-fit ml-auto font-medium border-primary border-2 border-solid rounded-full px-6 py-2 text-primary"
          >
            Înregistrare / Autentificare
          </Link>
        </div>
      </header>
      <Outlet />{" "}
      <p className="py-6 text-tertiary text-center mt-auto">
        © 2024 Toate drepturile rezervate.
      </p>
    </div>
  );
};

export default UserLayout;
